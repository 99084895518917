<template>
  <div class="yellow_solid"></div>
  <div class="bottom_bar">
    <div class="info_list wow animated fadeInUp">
      <div class="left">
        <div class="box">
          <div class="icon">
            <img src="@/assets/index/link.png">
          </div>
          <div class="name">
            <div>{{$t('bottom.tradeWebsite')}}：</div>
            <div>{{$t('bottom.aliLink')}}：</div>
          </div>
          <div class="link">
            <div style="cursor: pointer;" @click="goPath('https://www.shengdafiltration.com/')">https://www.shengdafiltration.com/</div>
            <div style="cursor: pointer;" @click="goPath('https://sdyyglq.1688.com/')">https://sdyyglq.1688.com/</div>
          </div>
        </div>
        <div class="box">
          <div class="icon">
            <img src="@/assets/index/phone.png">
          </div>
          <div class="name">
            <div>{{$t('bottom.domestic')}}：</div>
            <div>{{$t('bottom.division')}}：</div>
          </div>
          <div class="link">
            <div>{{phoneObj.phone}}</div>
            <div>{{phoneObj.phone_en}}</div>
          </div>
        </div>
        <div class="box">
          <div class="icon">
            <img src="@/assets/index/location.png">
          </div>
          <div class="name">
            <div>{{$t('bottom.comAddress')}}：</div>
            <div>{{$t('bottom.address')}}</div>
          </div>
        </div>
        <div class="box">
          <div class="icon">
            <img src="@/assets/index/email.png">
          </div>
          <div class="name">
            <div>{{$t('bottom.enEmail')}}：</div>
            <div>sales@sdfilters.com</div>
          </div>
        </div>
      </div>
      <div class="right" v-if="qrCode">
        <div class="qr_code">
          <img src="@/assets/index/qr_code1.png">
        </div>
      </div>
      <div class="right_en" v-else>
        <div class="tips">
          <img src="@/assets/index/lingying.png" @click="goPath('https://www.linkedin.com/company/xinxiang-shengda-filtration-technique-co-ltd/mycompany/')">
        </div>
        <div class="tips">
          <img src="@/assets/index/facebook.png" @click="goPath('https://www.facebook.com/profile.php?id=100092851355092')">
        </div>
        <div class="tips">
          <img src="@/assets/index/tiktok.png" @click="goPath('https://www.tiktok.com/@shengda_filtration')">
        </div>
        <div class="tips">
          <img src="@/assets/index/twitter.png" @click="goPath('')">
        </div>
      </div>
    </div>
    <div class="dv"></div>
    <div class="info_bottom">
      <div class="left">
        <div class="top">
          <div class="box wow animated fadeInUp">
            <div class="icon1">
              <img src="@/assets/index/form_name.png">
            </div>
            <input type="text" class="input" :placeholder="$t('bottom.enterName')" v-model="name">
          </div>
          <div class="box wow animated fadeInUp">
            <div class="icon2">
              <img src="@/assets/index/form_phone.png">
            </div>
            <input type="text" class="input" :placeholder="$t('bottom.enterMobile')" v-model="mobile">
          </div>
        </div>
        <textarea name="liuyan" class="center wow animated fadeInUp" :placeholder="$t('bottom.enterMsg')" v-model="msg" />
        <div class="bottom wow animated fadeInUp" @click="submit()">
          {{$t('bottom.submit')}}
        </div>
      </div>
      <div class="right">
        <div class="type wow animated fadeInUp" v-for="(item,index) in bottomType" :key="index">
          <div class="title">
            {{item.title}}
          </div>
          <div class="tags_list">
            <div class="tag_item text_go wow animated fadeInUp" v-for="item in item.tags" @click="goInfo(index,item)">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="fl">
      <div class="desc">
        备案号：豫ICP备2022024516号-1
      </div>
      <a href="http://61.54.99.22:8099/" class="login">
        ERP登录
      </a>
    </div>
    <div class="go_top">
      <img src="@/assets/index/goTop.png" @click="goBack">
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import {onMounted} from 'vue'
import { WOW } from "wowjs";
import { getProduct,getHangy } from '@/api';
import { ElMessage,ElMessageBox } from 'element-plus'
import {sendMsg,getPhone} from '@/api'
import router from '@/router';
import { getCurrentInstance } from "vue";
const { $t } = getCurrentInstance().proxy;

let qrCode = ref(true);
let phoneObj = ref({
  phone:"",
  phone_en:""
})
onMounted(()=>{
  new WOW({
    live:false
  }).init()
  if(localStorage.getItem('locale')=='en'){
    qrCode.value = false
  }
  getPhone().then(res=>{
    phoneObj.value = res.data.data
  })
  getProduct().then(res=>{
    let response = res.data
    let array1 = [...response.data.product]

    array1.forEach(item=>{
      bottomType.value[1].tags.push(item)
    })
  })
  getHangy().then(res=>{
    let response = res.data
    let array2 = [...response.data.hangye]
    array2.forEach(item=>{
      bottomType.value[0].tags.push(item)
    })
  })
})
let bottomType = ref([
  {
    title:$t('bottom.industry'),
    tags:[]
  },
  {
    title:$t('bottom.classification'),
    tags:[]
  }
])
function goBack(){
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}
// 跳转外部网页
function goPath(url){
  if(url==''){
    ElMessage({
      message:'There are currently no redirect pages available.'
    })
    return
  }
  window.open(url,"_blank")
}
// 跳转
function goInfo(index,item){
  console.log(index,item)
  if(index==0){
    router.push({
      path:'/industryInfo',
      query:{id:item.id}
    })
  }
  if(index==1){
    router.push({
      path:'/productInfo',
      query:{id:item.id}
    })
  }
}
// 提交留言
let name = ref('')
let mobile = ref('')
let msg = ref('')
function submit(){
  if(name.value=='' || mobile.value=='' || msg.value==''){
    ElMessageBox.alert('请填写完整信息','提示',{
      confirmButtonText: '确认',
      callback:(action)=>{

      }
    })
    return
  }
  
  sendMsg({name:name.value,mobile:mobile.value,msg:msg.value}).then(res=>{
    let response = res.data
    console.log(response)
    
    if(response.code==1){
      ElMessage({
        message:response.msg,
        type:'success'
      })
      name.value = ''
      mobile.value = ''
      msg.value = ''
    } else{
      ElMessage({
        message:response.msg
      })
    }
  })
}
</script>
<style lang="scss" scoped>
  .text_go{
    transition: all .3s ease-in-out;
    cursor: pointer;
  }
  .text_go:hover{
    padding-left: .625rem;
  }
  .yellow_solid{
    width: 100%;
    height: .4375rem;
    background-color: #FFB91D;
  }
  .bottom_bar{
    height: 36.25rem;
    width: 100%;
    background: url('@/assets/index/bottomBanner.png') no-repeat;
    background-size: cover;
    background-position: center;
    box-sizing: border-box;
    padding: 4.8125rem 4.75rem;
    color: rgba($color: #fff, $alpha: .5);
    position: relative;                   
    .info_list{
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 5.25rem;
      .left{
        width: 100%;
        display: flex;
        justify-content: space-between;
        .box{
          display: flex;
          font-size: .875rem;
          line-height: 1.5rem;
          .icon{
            width: 2.25rem;
            height: 2.25rem;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            img{
              width: 100%;
              height: 100%;
            }
          }
          .name{
            display: flex;
            flex-direction: column;
            text-align: left;
            margin: 0 .5rem 0 .75rem;
          }
          .link{
            display: flex;
            text-align: left;
            flex-direction: column;
          }
        }
        .box:first-child{margin-left: 0;}
      }
      .right{
        display: flex;
        height: 5.25rem;
        align-items: center;
        .qr_code{
          margin-left: 1.25rem;
          display: flex;
          width: 5.25rem;
          height: 5.25rem;
          align-items: center;
          justify-content: center;
          img{
            width: 100%;
            height: 100%;
          }
        }
      }
      .right_en{
        display: flex;
        justify-content: space-between;
        align-content: space-between;
        flex-wrap: wrap;
        width: 5.375rem;
        height: 5.375rem;
        margin-left: 1.5rem;
        .tips{
          cursor: pointer;
          width: 2rem;
          height: 2rem;
          img{
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .dv{
      height: .0625rem;
      width: 100%;
      background-color: rgba($color: #fff, $alpha: .1);
      margin-top: 2.25rem;
      margin-bottom: 3.4375rem;
    }
    .info_bottom{
      display: flex;
      width: 100%;
      .left{
        margin-top: .25rem;
        width: 33rem;
        display: flex;
        flex-direction: column;
        font-size: 1rem;
        .top{
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: space-between;
          .box{
            width: 16.1875rem;
            height: 3rem;
            border-radius: .25rem;
            background-color: rgba($color: #fff, $alpha: .08);
            padding: 0 1.25rem;
            display: flex;
            align-items: center;
            box-sizing: border-box;
            margin-left: .625rem;
            .icon1{
              width: .9375rem;
              height: .875rem;
              flex-shrink: 0;
              img{width: 100%;height: 100%;}
            }
            .icon2{
              width: .75rem;
              height: 1.1875rem;
              flex-shrink: 0;
              img{width: 100%;height: 100%;}
            }
            .input{
              width: 100%;
              margin-left: .625rem;
              height: 1.375rem;
              border: none;
              background-color: transparent;
              color: #fff;
              font-size: 1rem;
            }
            .input:focus{
              border: none;
              outline: none;
            }
            .input::placeholder{
              color: rgba($color: #fff, $alpha: .5);
              font-size: 1rem;
            }
          }
          .box:first-child{margin-left: 0;}
        }
        .center{
          margin: .625rem 0;
          width: 100%;
          border-radius: .25rem;
          height: 8.125rem;
          background-color: rgba($color: #fff, $alpha: .08);
          box-sizing: border-box;
          padding: 1rem 1.25rem;
          outline: none;
          border: none;
          resize: none;
          color: #fff;
          font-size: 1rem;
          font-family: SourceHanSansCN;
        }
        .center::placeholder{
          color: rgba($color: #fff, $alpha: .5);
          font-family: SourceHanSansCN;
          font-size: 1rem;
        }
        .bottom{
          height: 3rem;
          width: 100%;
          border-radius: .25rem;
          background-color: rgba($color: #fff, $alpha: .08);
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          cursor: pointer;
          transition: all .3s ease-in-out;
        }
        .bottom:hover{
          background-color: rgba($color: #fff, $alpha: .5);
          color: #000;
        }
      }
      .right{
        flex-grow: 1;
        margin-left: 11.375rem;
        display: flex;
        flex-direction: column;
        .type{
          display: flex;
          margin-top: 1.125rem;
          .title{
            width: 9.375rem;
            font-size: 1.125rem;
            flex-shrink: 0;
            color: rgba($color: #fff, $alpha: .8);
            text-align: start;
          }
          .tags_list{
            flex-grow: 1;
            font-size: 1rem;
            display: flex;
            // line-height: 1.875rem;
            flex-wrap: wrap;
            .tag_item{
              margin-top: .125rem;
              text-align: start;
              width: calc(100% / 6);
              flex-shrink: 0;
              margin-bottom: 1.25rem;
              box-sizing: border-box;
              color: rgba($color: #fff, $alpha: .5);
            }
          }
        }
        .type:first-child{
          margin-top: 0;
        }
      }
    }
    .fl{
      position: absolute;
      right: 4.75rem;
      bottom: 1.25rem;
      display: flex;
      align-items: center;
      z-index: 2;
      .desc{
        font-size: .875rem;
        margin-right: 2.5rem;
      }
      .login{
        background-color: rgba($color: #1A59FF, $alpha: .2);
        color: rgba($color: #fff, $alpha: .5);
        width: 7.5rem;
        text-decoration: none;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.125rem;
        line-height: 1.25rem;
        border-radius: 1.5625rem;
        cursor: pointer;
      }
    }
    .go_top{
      width: 3.75rem;
      height: 3.75rem;
      bottom: 0;
      right: 0;
      position: absolute;
      z-index: 99;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
</style>