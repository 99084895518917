<template>
  <div class="bottom">
    <div class="info_list">
      <div class="info_item" v-for="(item,index) in infoList" :key="index">
        <div class="top">
          {{ item }}
        </div>
        <div class="info_bottom"></div>
      </div>
    </div>
    <div class="from">
      <div class="person_info">
        <div class="info_box">
          <div class="from_icon">
            <img src="@/assets/_mobile/personName.png" alt="">
          </div>
          <input type="text" class="input" :placeholder="$t('bottom.enterName')" v-model="name">
        </div>
        <div class="info_box">
          <div class="from_icon">
            <img src="@/assets/_mobile/personPhone.png" alt="">
          </div>
          <input type="text" class="input" :placeholder="$t('bottom.enterMobile')" v-model="mobile">
        </div>
      </div>
      <textarea name="" class="person_msg" :placeholder="$t('bottom.enterMsg')" v-model="msg"></textarea>
      <div class="person_btn" @click="submit()">
        提交
      </div>
    </div>
    <div class="qr_code" v-if="qrCode">
      <div class="code">
        <img src="@/assets/_mobile/bottomQrCode2.png" alt="">
      </div>
    </div>
    <div class="qr_code_en" v-else>
      <div class="tips">
        <img src="@/assets/index/lingying.png" @click="goPath('https://www.linkedin.com/company/xinxiang-shengda-filtration-technique-co-ltd/mycompany/')">
      </div>
      <div class="tips">
        <img src="@/assets/index/facebook.png" @click="goPath('https://www.facebook.com/profile.php?id=100092851355092')">
      </div>
      <div class="tips">
        <img src="@/assets/index/tiktok.png" @click="goPath('https://www.tiktok.com/@shengda_filtration')">
      </div>
      <div class="tips">
        <img src="@/assets/index/twitter.png" @click="goPath('')">
      </div>
    </div>
    <div class="police">
      <div class="police_info">
        备案号：豫ICP备2022024516号-1
      </div>
      <div class="erp_login">
        ERP登录
      </div>
    </div>
  </div>
</template>

<script setup>
import {reactive,ref,onMounted} from 'vue'
import { ElMessage,ElMessageBox } from 'element-plus'
import {sendMsg,getPhone} from '@/api'
import { useI18n } from 'vue-i18n'
import { getCurrentInstance } from "vue";
const { $t } = getCurrentInstance().proxy;

//国际化
let {locale} = useI18n()

// 
let qrCode = ref(true);
let phoneObj = ref({
  phone:"",
  phone_en:""
})
let infoList = ref([])
onMounted(()=>{
  if(localStorage.getItem('locale')=='en'){
    qrCode.value = false
  }
  getPhone().then(res=>{
    console.log(res.data)
    phoneObj.value = res.data.data
  })
  setTimeout(() => {
    infoList.value = [
      `${$t('bottom.tradeWebsite')}：https://www.shengdafiltration.com/`,
      `${$t('bottom.aliLink')}：https://sdyyglq.1688.com/`,
      `${$t('bottom.domestic')}：${phoneObj.value.phone}`,
      `${$t('bottom.division')}：${phoneObj.value.phone_en}`,
      `${$t('bottom.comAddress')}：${$t('bottom.address')}`,
      `${$t('bottom.enEmail')}：sales@sdfilters.com`
    ]
  }, 200);
})
// 跳转外部网页
function goPath(url){
  if(url==''){
    ElMessage({
      message:'There are currently no redirect pages available.'
    })
    return
  }
  window.open(url,"_blank")
}
// 提交留言
let name = ref('')
let mobile = ref('')
let msg = ref('')
function submit(){
  if(name.value=='' || mobile.value=='' || msg.value==''){
    ElMessageBox.alert('请填写完整信息','提示',{
      confirmButtonText: '确认',
      callback:(action)=>{

      }
    })
    return
  }
  let headers = ref('');
  if(locale.value=='zh'){
    headers.value = '0'
  } else {
    headers.value = '1'
  }
  sendMsg({name:name.value,mobile:mobile.value,msg:msg.value},{language:headers.value}).then(res=>{
    let response = res.data
    console.log(response)
    
    if(response.code==1){
      ElMessage({
        message:response.msg,
        type:'success'
      })
      name.value = ''
      mobile.value = ''
      msg.value = ''
    } else{
      ElMessage({
        message:response.msg
      })
    }
  })
}
</script>

<style lang='scss' scoped>
.bottom{
  height: 1526px;
  max-width: 750px;
  overflow-x: hidden;
  background: url('@/assets/_mobile/bottomBg.png') no-repeat;
  background-size: cover;
  box-sizing: border-box;
  padding: 50px 30px 0;
  .info_list{
    display: flex;
    flex-direction: column;
    width: 100%;
    .info_item{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: start;
      .top{
        font-size: 26px;
        display: flex;
        align-items: center;
        height: 37px;
        color: #fff;
        opacity: .8;
      }
      .info_bottom{
        width: 100%;
        height: 1px;
        background-color: rgba($color: #fff, $alpha: .2);
        margin: 34px 0;
      }
    }
  }
  .from{
    display: flex;
    flex-direction: column;
    .person_info{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 10px;
      .info_box{
        width: 340px;
        height: 76px;
        box-sizing: border-box;
        padding: 20px;
        background-color: rgba($color: #fff, $alpha: .08);
        border-radius: 4px;
        display: flex;
        .from_icon{
          width: 36px;
          height: 36px;
          flex-shrink: 0;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .input{
          margin-left: 20px;
          width: 100%;
          font-size: 28px;
          color: rgba($color: #fff, $alpha: .3);
          border: none;
          background-color: transparent;
        }
        .input:focus{
          border: none;
          outline: none;
        }
        .input::placeholder{
          color: rgba($color: #fff, $alpha: .3);
          font-size: 28px;
        }
      }
    }
    .person_msg{
      width: 100%;
      border-radius: 4px;
      height: 180px;
      background-color: rgba($color: #fff, $alpha: .08);
      box-sizing: border-box;
      padding: 20px;
      outline: none;
      border: none;
      resize: none;
      color: rgba($color: #fff, $alpha: .3);
      font-size: 28px;
      margin-bottom: 20px;
    }
    .person_msg::placeholder{
      color: rgba($color: #fff, $alpha: .3);
      font-size: 28px;
    }
    .person_btn{
      width: 100%;
      height: 88px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba($color: #fff, $alpha: .08);
      color: rgba($color: #fff, $alpha: .8);
      font-size: 30px;
    }
  }
  .qr_code{
    display: flex;
    margin: 58px 0 94px;
    align-items: center;
    justify-content: center;
    .code{
      width: 200px;
      height: 200px;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
  .qr_code_en{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 250px;
    height: 250px;
    margin: 50px auto;
    .tips{
      width: 100px;
      height: 100px;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
  .police{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .police_info{
      color: rgba($color: #fff, $alpha: .5);
      font-size: 28px;
      line-height: 30px;
    }
    .erp_login{
      width: 160px;
      height: 60px;
      border-radius: 30px;
      background-color: rgba($color: #1A59FF, $alpha: .2);
      font-size: 30px;
      color: rgba($color: #fff, $alpha: .7);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>