import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import router from './router'
import store from './store'
import '@/utils/rem.js'
import animated from "animate.css";
import './assets/font/font.css'
// 全局注册axios
import axios from './api/request'
// 导入语言包
import i18n from './i18n/index';
// 引入无缝滚动动画
import vue3SeamlessScroll from 'vue3-seamless-scroll'


const app = createApp(App)
app.config.globalProperties.$axios = axios
app
  .use(store)
  .use(router)
  .use(i18n)
  .use(vue3SeamlessScroll)
  .use(animated)
  .use(ElementPlus)
  .mount('#app')

