import { ref } from 'vue';
import http from './request'
import { useI18n } from 'vue-i18n'

//国际化
function gj(){
  let {locale} = useI18n()
  console.log(locale.value)
  let headers = ref('');
  if(locale.value=='zh'){
    headers.value = '0'
  } else {
    headers.value = '1'
  }
  return headers.value
}
// v2新增
export function getPhone(data){
  return http({
    url:"/index/coninfo",
    method:"POST",
    data,
    headers:{language:gj()}
  })
}
export function search(data,headers){
  return http({
    url:"/product/searchInfo",
    method:"POST",
    data,
    headers:headers
  })
}
// 首页
export function getIndex(data){
  return http({
    url:"/index/index",
    method:"POST",
    data,
    headers:{language:gj()}
  })
}
// 关于胜达
export function getAbout(data){
  return http({
    url:"/index/about",
    method:"POST",
    data:data,
    headers:{language:gj()}
  })
}
// 下载资料 
export function downFile(data,headers){
  return http({
    url:"/index/down",
    method:"POST",
    data:data,
    headers:headers
  })
}
// 网站留言
export function sendMsg(data,headers){
  return http({
    url:"/news/msg",
    method:"POST",
    data:data,
    headers:headers
  })
}

// 核心优势
export function getCore(data){
  return http({
    url:"/index/yanfa",
    method:"POST",
    data:data,
    headers:{language:gj()}
  })
}
// 应用行业 主页
export function getHangy(data){
  return http({
    url:"/product/hangy",
    method:"POST",
    data:data,
    headers:{language:gj()}
  })
}
// 应用行业 详细列表 树图
export function getHangySub(data){
  return http({
    url:"/product/hangy_sub",
    method:"POST",
    data:data,
    headers:{language:gj()}
  })
}
// 产品中心 列表
export function getProduct(data){
  return http({
    url:"/product/product",
    method:"POST",
    data:data,
    headers:{language:gj()}
  })
}
// 产品中心 详情
export function getProductDet(data,headers){
  return http({
    url:"/product/product_det",
    method:"POST",
    data:data,
    headers:headers
  })
}
// 产品中心 详情2
export function getProductDetail(data,headers){
  return http({
    url:"/product/product_detail",
    method:"POST",
    data:data,
    headers:headers
  })
}
// 新闻中心
export function getNews(data,headers){
  return http({
    url:"/news/list",
    method:"POST",
    data:data,
    headers:headers
  })
}
// 新闻详情
export function getNewsDetail(data,headers){
  return http({
    url:"/news/detail",
    method:"POST",
    data:data,
    headers:headers
  })
}

// 加入我们
export function getJoin(data){
  return http({
    url:"/news/join",
    method:"POST",
    data:data,
    headers:{language:gj()}
  })
}