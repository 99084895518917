<template>
  <Header></Header>
  <div class="containers">
    <div class="banner">
      <!-- <el-carousel height="117.066667vw" :autoplay="true" :interval="2000">
        <el-carousel-item v-for="(item,index) in swiperList" :key="index">
          
        </el-carousel-item>
      </el-carousel> -->
      <div class="bg_show">
        <el-carousel height="1300px">
          <el-carousel-item v-for="(item,index) in swiperList" :key="index">
            <div class="bg">
              <img :src="'https://www.sdfilters.com/'+item.image">
            </div>
            <div class="title">
              <div class="top">{{item.title}}</div>
              <div class="bottom">{{item.desc}}</div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="icon_list">
        <div class="icon_item" v-for="(item,index) in iconList" :key="index" @click="goIndustry(index)">
          <div class="icon_show">
            <img :src="item.icon">
          </div>
          <div class="icon_name">
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="center" id="center">
      <div class="show">
        <div class="center_bg">
          <img :src="'https://www.sdfilters.com/'+simage.image" >
        </div>
        <div class="title" id="title">
          {{ simage.title }}
        </div>
        <div class="center_icon" id="show">
          <img src="@/assets/_mobile/home/centerIcon.png">
        </div>
      </div>
    </div>
    <div class="about">
      <div class="title">{{ $t('home.about') }}</div>
      <div class="desc">{{about.aboutdesc}}</div>
      <div class="look">
        <div class="txt" @click="viewVideo(about.aboutvideo)">观看视频</div>
        <div class="forward">
          <img src="@/assets/_mobile/home/homeForward.png">
        </div>
      </div>
    </div>
    <div class="bottom_bar">
      <img :src="'https://www.sdfilters.com/'+about.aboutimg">
    </div>
  </div>
  <Bottom></Bottom>
</template>

<script setup>
import Header from './component/mobileHeader'
import Bottom from './component/mobileBottom'
import {getIndex} from '@/api'
import { ref,reactive,nextTick,onMounted } from 'vue'
import router from '@/router';
import { useRoute } from 'vue-router'
import { getCurrentInstance } from "vue";
const { $t } = getCurrentInstance().proxy;


let swiperList = ref([])
let simage = ref({})
let about = ref({})

onMounted(()=>{
  getIndex().then((res)=>{
    console.log(res.data.data)
    let data = res.data.data
    swiperList.value =  data.ads
    simage.value = data.simage
    about.value = data.about
  })
})
let route = useRoute()
if(route.query.refresh==='true'){
  router.push({ path: '/', query: { refresh: 'false' } })
  // setTimeout(()=>{
  //   window.location.reload()
  // },200)
}

let iconList = reactive([
  {
    icon:require('@/assets/_mobile/home/icon1.png'),
    name:$t('home.icon1')
  },
  {
    icon:require('@/assets/_mobile/home/icon2.png'),
    name:$t('home.icon2')
  },
  {
    icon:require('@/assets/_mobile/home/icon3.png'),
    name:$t('home.icon3')
  },
  {
    icon:require('@/assets/_mobile/home/icon4.png'),
    name:$t('home.icon4')
  },
  {
    icon:require('@/assets/_mobile/home/icon5.png'),
    name:$t('home.icon5')
  },
  {
    icon:require('@/assets/_mobile/home/icon6.png'),
    name:$t('home.icon6')
  },
  {
    icon:require('@/assets/_mobile/home/icon7.png'),
    name:$t('home.icon7')
  },
  {
    icon:require('@/assets/_mobile/home/icon8.png'),
    name:$t('home.icon8')
  },
  {
    icon:require('@/assets/_mobile/home/icon9.png'),
    name:$t('home.icon9')
  },
  {
    icon:require('@/assets/_mobile/home/icon10.png'),
    name:$t('home.icon10')
  }
])

function goIndustry(index){
  router.push({
    path:'/industryInfo',
    query:{id:10-index}
  })
}

// 观看视频
function viewVideo(path){
  let url ="https://www.sdfilters.com"+path
  window.open(url,"_blank")
}

nextTick(()=>{
  const item = document.querySelector('.center_icon')
  const ground = document.querySelector('#center')
  const show = document.querySelector('#show')
  const title = document.querySelector('#title')
  //
  function createAnimation(scrollStart,scrollEnd,valueStart,valueEnd) {
    return function(scroll){
      if(scroll <= scrollStart){
        return valueStart
      }
      if(scroll >= scrollEnd){
        return valueEnd
      }
      return valueStart + (valueEnd - valueStart) * (scroll - scrollStart) / (scrollEnd - scrollStart)
    }
  }
  //
  const animationMap = new Map()
  function getDomAnimation(scrollStart,scrollEnd,dom){
    scrollStart = scrollStart
    const scaleAnimation = createAnimation(scrollStart,scrollEnd,1,2.4)
    const yAnimation = createAnimation(scrollStart,scrollEnd,0,60)
    const transform = function(scroll){
      return `translate(0,${yAnimation(scroll)}px) scale(${scaleAnimation(scroll)})`
      // translate(0,${yAnimation(scroll)}px) 
    }
    const opacity = function(scroll){
      if(scroll <= scrollEnd-100){
        return `1`
      } else{
        return `0`
      }
    }
    return {
      transform,
      opacity
    }
  }
  function showOpacity(scrollStart,scrollEnd,dom){
    scrollStart = scrollStart
    const opacity = function(scroll){
      if(scroll <= scrollEnd-100){
        return `1`
      } else{
        return `0`
      }
    }
    return {
      opacity
    }
  }
  //
  function updateMap(){
    animationMap.clear();
    const playGroundRect = ground.getBoundingClientRect();
    // 开始动画的距离
    const scrollStart = playGroundRect.top + window.scrollY
    // 结束动画的距离  相当于playground的高度
    const scrollEnd = playGroundRect.bottom + window.scrollY - window.innerHeight
    animationMap.set(item,getDomAnimation(scrollStart,scrollEnd,item))
    animationMap.set(title,showOpacity(scrollStart,scrollEnd,title))
  }
  updateMap()
  //
  function updateStyle(){
    const scroll = window.scrollY;
    for(let [dom,value] of animationMap){
      for(const cssProp in value){
        dom.style[cssProp] = value[cssProp](scroll);
      }
    }
  }
  updateStyle();
  window.addEventListener('scroll',updateStyle)
})
</script>

<style lang='scss' scoped>
.containers{
  box-sizing: border-box;
  max-width: 750px;
  .banner{
    width: 750px;
    height: 1300px;
    position: relative;
    overflow: hidden;
    display: flex;
    color: #FFF;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding-top: 59px;
    .bg_show{
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      .bg{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 0;
        display: flex;
        justify-content: center;
        img{
          height: 100%;
        }
      }
      .title{
        position: absolute;
        top: 251px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        width: 660px;
        text-align: start;
        display: flex;
        flex-direction: column;
        margin-bottom: 18px;
        .top{
          font-size: 44px;
          line-height: 68px;
          margin-bottom: 10px;
          font-weight: bold;
        }
        .bottom{
          width: 587px;
          line-height: 39px;
          font-size: 26px;
        }
      }
    }
    .icon_list{
      z-index: 1;
      position: absolute;
      top: 521px;
      left: 50%;
      transform: translateX(-50%);
      width: 740px;
      display: flex;
      flex-wrap: wrap;
      .icon_item:nth-child(3n+1){
        margin-left: 0;
      }
      .icon_item{
        margin-left: 10px;
        margin-bottom: 10px;
        width: 240px;
        height: 135px;
        display: flex;
        background-color: #00349B;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .icon_show{
          width: 50px;
          height: 50px;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .icon_name{
          margin-top: 10px;
          font-size: 22px;
        }
      }
    }
  }
  .center{
    height: 2600px;
    position: relative;
    .show{
      height: 1300px;
      position: sticky;
      top: 98px;
      background-color: #EEF5FF;
      overflow: hidden;
      z-index: 1;
      transition: .3s all ease-in-out;
      .center_bg{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 1300px;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .title{
        position: absolute;
        top: 166px;
        left: 50%;
        z-index: 2;
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 48px;
        font-weight: bold;
        line-height: 72px;
        width: 528px;
      }
      .center_icon{
        height: 1300px;
        transition: opacity .5s ease-in-out;
        img{
          height: 100%;
        }
      }
    }
  }
  .about{
    overflow: hidden;
    width: 750px;
    height: 750px;
    background-color: #FFB91D;
    box-sizing: border-box;
    padding: 100px 40px 0;
    display: flex;
    flex-direction: column;
    text-align: start;
    color: #222;
    .title{
      margin-bottom: 30px;
      font-size: 52px;
      font-weight: bold;
    }
    .desc{
      font-size: 30px;
      line-height: 45px;
      opacity: .8;
    }
    .look{
      display: flex;
      width: 180px;
      box-sizing: border-box;
      padding: 16px 22px;
      height: 60px;
      align-items: center;
      justify-content: space-between;
      background-color: #fff;
      margin-top: 120px;
      .txt{
        font-size: 20px;
      }
      .forward{
        width: 6.5px;
        height: 11.5px;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .bottom_bar{
    overflow: hidden;
    width: 750px;
    height: 424px;
    img{
      width: 100%;
      height: 100%;
    }
  }
}
</style>