
<link rel="stylesheet" href="css/animate.css" />
<template>
  <div class="container">
    <Headers></Headers>
    <div class="banner">
      <el-carousel indicator-position="none" height="calc(100vh - 4.5rem)">
        <el-carousel-item v-for="(item,index) in swiperList" :key="index">
          <div class="bg">
            <img :src="'https://www.sdfilters.com/'+item.image" />
          </div>
          <div class="left">
            <div class="title">
              {{item.title}}
            </div>
            <div class="desc">
              {{item.desc}}
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="right">
        <div v-for="(item,index) in list.list" :key="index" :style="[index>1?'margin-top:.25rem':'' , index%2 !=0?'margin-left:.3125rem':'']" v-bind:class="[index==list.index?'select':'','item']" @mouseenter="changeIndex(index)" @mouseleave="list.index=-1" @click="goIndustry(index)">
          <img class="icon-img" :src="[index==list.index?item.img:item.selectImg]" />
          <div class="label">{{ item.label }}</div>
        </div>
      </div>
    </div>
    <div class="center" id="center">
      <div class="show">
        <div class="center_bg">
          <img :src="'https://www.sdfilters.com/'+simage.image" v-if="simage">
        </div>
        <div class="title wow animated fadeInUp" id="center_title" v-if="simage">{{ simage.title }}</div>
        <div class="center_banner">
          <img src="@/assets/index/centerLogo.png">
        </div>
      </div>
    </div>
    <div class="bottom_banner">
      <div class="left wow animated fadeIn">
        <img :src="'https://www.sdfilters.com/'+about.aboutimg" v-if="about">
      </div>
      <div class="right">
        <div class="title wow animated fadeInUp">{{ $t('home.about') }}</div>
        <div class="desc wow animated fadeInUp" v-if="about">{{about.aboutdesc}}</div>
        <div class="look wow animated fadeInUp">
          <div class="text" @click="viewVideo(about.aboutvideo)">{{ $t('video') }}</div>
          <div class="forword">
            <img src="@/assets/index/arrow_forword.png">
          </div>
        </div>
      </div>
    </div>
    <BottomBar></BottomBar>
  </div>
  
</template>
<script setup>
import {getIndex} from '@/api'
import Headers from '@/components/pageHeader'
import BottomBar from '@/components/pageBottom'
import {ref,reactive,nextTick,onMounted} from 'vue'
import { useRoute } from 'vue-router'
import router from '@/router';
import { WOW } from "wowjs";
import { getCurrentInstance } from "vue";
const { $t } = getCurrentInstance().proxy;

let swiperList = ref([])
let about = ref({})
let simage = ref({})

onMounted(()=>{
  new WOW({
    live:false
  }).init()
  getIndex().then((res)=>{
    console.log(res.data.data)
    let data = res.data.data
    swiperList.value =  data.ads
    about.value = data.about
    simage.value = data.simage
  })
})

let route = useRoute()
if(route.query.refresh==='true'){
  router.push({ path: '/index', query: { refresh: 'false' } })
}
let list = reactive({
  index:-1,
  list:[
    {
      label:$t('home.icon1'),
      img: require('@/assets/index/icons/icon1.png'),
      selectImg: require('@/assets/index/icons/selectIcon1.png')
    },{
      label:$t('home.icon2'),
      img: require('@/assets/index/icons/icon2.png'),
      selectImg: require('@/assets/index/icons/selectIcon2.png')
    },{
      label:$t('home.icon3'),
      img: require('@/assets/index/icons/icon3.png'),
      selectImg: require('@/assets/index/icons/selectIcon3.png')
    },{
      label:$t('home.icon4'),
      img: require('@/assets/index/icons/icon4.png'),
      selectImg: require('@/assets/index/icons/selectIcon4.png')
    },{
      label:$t('home.icon5'),
      img: require('@/assets/index/icons/icon5.png'),
      selectImg: require('@/assets/index/icons/selectIcon5.png')
    },{
      label:$t('home.icon6'),
      img: require('@/assets/index/icons/icon6.png'),
      selectImg: require('@/assets/index/icons/selectIcon6.png')
    },{
      label:$t('home.icon7'),
      img: require('@/assets/index/icons/icon7.png'),
      selectImg: require('@/assets/index/icons/selectIcon7.png')
    },{
      label:$t('home.icon8'),
      img: require('@/assets/index/icons/icon8.png'),
      selectImg: require('@/assets/index/icons/selectIcon8.png')
    },{
      label:$t('home.icon9'),
      img: require('@/assets/index/icons/icon9.png'),
      selectImg: require('@/assets/index/icons/selectIcon9.png')
    },{
      label:$t('home.icon10'),
      img: require('@/assets/index/icons/icon10.png'),
      selectImg: require('@/assets/index/icons/selectIcon10.png')
    }
  ]
})
function changeIndex(index){
  list.index = index
}
function goIndustry(index){
  router.push({
    path:'/industryInfo',
    query:{id:10-index}
  })
}

// 观看视频
function viewVideo(path){
  let url ="https://www.sdfilters.com"+path
  window.open(url,"_blank")
}

nextTick(()=>{
  const item = document.querySelector('.center_banner')
  const ground = document.querySelector('#center')
  const title = document.querySelector('#center_title')
  //
  function createAnimation(scrollStart,scrollEnd,valueStart,valueEnd) {
    return function(scroll){
      if(scroll <= scrollStart){
        return valueStart
      }
      if(scroll >= scrollEnd){
        return valueEnd
      }
      return valueStart + (valueEnd - valueStart) * (scroll - scrollStart) / (scrollEnd - scrollStart)
    }
  }
  //
  const animationMap = new Map()
  function getDomAnimation(scrollStart,scrollEnd,dom){
    scrollStart = scrollStart
    const scaleAnimation = createAnimation(scrollStart,scrollEnd,.5,1.65)
    const yAnimation = createAnimation(scrollStart,scrollEnd,0,item.clientHeight/2 - (dom.offsetTop/2) - dom.clientHeight - 100)
    const transform = function(scroll){
      return `translate(0,${yAnimation(scroll)}px) scale(${scaleAnimation(scroll)})`
    }
    const opacity = function(scroll){
      if(scroll <= scrollEnd-500){
        return `1`
      } else{
        return `0`
      }
    }
    return {
      transform,
      opacity
    }
  }
  //
  function getTitleAnimation(scrollStart,scrollEnd,dom){
    scrollStart = scrollStart
    const display = function(scroll){
      if(scroll <= scrollEnd-500){
        return `block`
      } else{
        return `none`
      }
    }
    return {
      display
    }
  }
  function updateMap(){
    animationMap.clear();
    const playGroundRect = ground.getBoundingClientRect();
    // 开始动画的距离
    const scrollStart = playGroundRect.top + window.scrollY
    // 结束动画的距离  相当于playground的高度
    const scrollEnd = playGroundRect.bottom + window.scrollY - window.innerHeight
    animationMap.set(item,getDomAnimation(scrollStart,scrollEnd,item))
    animationMap.set(title,getTitleAnimation(scrollStart,scrollEnd,title))
  }
  updateMap()
  //
  function updateStyle(){
    const scroll = window.scrollY;
    for(let [dom,value] of animationMap){
      for(const cssProp in value){
        dom.style[cssProp] = value[cssProp](scroll);
      }
    }
  }
  updateStyle();
  window.addEventListener('scroll',updateStyle)
})

</script>

<style lang="scss" scoped>

.container{
    box-sizing: border-box;
    color: #FFFFFF;
    .text_go{
      transition: all .3s ease-in-out;
      cursor: pointer;
    }
    .text_go:hover{
      padding-left: .625rem;
    }
    .banner{
      height: calc(100vh - 4.5rem);
      width: 100%;
      position: relative;
      .bg{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 1;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .left{
        z-index: 2;
        position: absolute;
        left: 15rem;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        flex-direction: column;
        .title{
          font-size: 3.5rem;
          line-height: 4.5625rem;
          font-weight: bold;
          width: 28rem;
          text-align: start;
        }
        .desc{
          margin-top: 1.4375rem;
          font-size: 1.375rem;
          line-height: 1.875rem;
          width: 40rem;
          text-align: start;
        }
      }
      .right{
        z-index: 2;
        position: absolute;
        display: flex;
        flex-wrap: wrap;
        width: 37.5625rem;
        right: 8.375rem;
        top: 50%;
        transform: translateY(-50%);
        .item{
          cursor: pointer;
          width: 15.5rem;
          height: 8.75rem;
          display: flex;
          flex-direction: column;
          padding: 1.5625rem;
          align-items: center;
          box-sizing: border-box;
          background-color: #00349B;
          transition: all .3s ease-in-out;
          .icon-img{
            width: 3.125rem;
            height: 3.125rem;
            margin-bottom: .625rem;
          }
          .label{
            font-size: 1.375rem;
          }
        }
        .select{
          background-color: #FFB91D;
          .label{
            color: #222222;
          }
        }
      }
    }
    .center{
      height: calc((100vh - 4.5rem)*2);
      position: relative;
      .show{
        height: calc(100vh - 4.5rem);
        position: sticky;
        top: 4.5rem;
        z-index: 1;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        .center_bg{
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          height: calc(100vh - 4.5rem);
          img{
            width: 100%;
            height: 100%;
          }
        }
        .title{
          z-index: 2;
          font-size: 3rem;
          position: absolute;
          font-weight: bold;
          top: 9.4375rem;
          color: #000;
        }
        .center_banner{
          transition: opacity .5s ease-in-out;
          height: var('cacl((100vh-4.5rem)*2)');
          img{
            height: 100%;
          }
        }
      }
    }
    .bottom_banner{
      height: 34.3125rem;
      background-color: #FFB91D;
      box-sizing: border-box;
      padding-bottom: .4375rem;
      display: flex;
      .left{
        width: 50%;
        height: 100%;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .right{
        width: 50%;
        height: 100%;
        box-sizing: border-box;
        padding: 7.125rem 6.375rem 4.9375rem 5rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        color: #222;
        .title{
          font-size: 3rem;
          font-weight: 800;
        }
        .desc{
          text-align: start;
          width: 48.625rem;
          font-size: 1.375rem;
          line-height: 1.875rem;
          color: rgba($color: #222, $alpha: .8);
          margin: 2.625rem 0 3.75rem;
        }
        .look{
          cursor: pointer;
          width: 11.25rem;
          height: 3.75rem;
          box-sizing: border-box;
          padding: .9375rem 1.4375rem 1.0625rem 1.3125rem;
          align-items: center;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: #fff;
          border-radius: .25rem;
          font-size: 1.25rem;
          color: #222;
          line-height: 1.25rem;
          transition: all .3s ease-in-out;
          .forword{
            width: .4063rem;
            height: .7281rem;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
              width: 100%;
              height: 100%;
            }
          }
        }
        .look:hover{
          padding-left: 1.9375rem;
        }
      }
    }
  }

</style>
