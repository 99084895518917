import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import MobilePage from '../mobile/MobilePage.vue'
import { reactive,watch } from 'vue'
const windowSize = reactive({
  width: window.innerWidth
})

window.addEventListener('resize', () => {
  windowSize.width = window.innerWidth
})
const routes = [
  {
    path: '/',
    redirect:'/index',
    component: windowSize.width < 768 ? MobilePage : HomeView,
  },
  {
    path:'/center',
    redirect:'/index'
  },
  {
    path: '/index',
    name: 'home',
    component: windowSize.width < 768 ? MobilePage : HomeView,
    meta:{
      title:"首页",
      content: {
        keywords: '胜达',
        description: '胜达官网首页'
      },
      refresh:true
    }
  },
  {
    path: '/about',
    name: 'about',
    component: windowSize.width < 768 ? ()=>import("@/mobile/MobileAbout.vue") : ()=>import("@/views/About.vue")
  },
  {
    path: '/core',
    name: 'core',
    component: windowSize.width < 768 ? ()=>import("@/mobile/MobileCore.vue") : ()=>import("@/views/Core.vue")
  },
  {
    path: '/industry',
    name: 'industry',
    component: windowSize.width < 768 ? ()=>import("@/mobile/MobileIndustry.vue") : ()=>import("@/views/Industry.vue")
  },
  {
    path: '/industryInfo',
    name: 'industryInfo',
    component: windowSize.width < 768 ? ()=>import("@/mobile/industry/MobileIndustryInfo.vue") : ()=>import("@/views/industry/IndustryInfo.vue")
  },
  {
    path: '/product',
    name: 'product',
    component: windowSize.width < 768 ? ()=>import("@/mobile/MobileProduct.vue") : ()=>import("@/views/Product.vue")
  },
  {
    path: '/productInfo',
    name: 'productInfo',
    component: windowSize.width < 768 ? ()=>import("@/mobile/product/MobileProductInfo.vue") : ()=>import("@/views/product/ProductInfo.vue")
  },
  {
    path: '/news',
    name: 'news',
    component: windowSize.width < 768 ? ()=>import("@/mobile/MobileNews.vue") : ()=>import("@/views/News.vue")
  },
  {
    path: '/newsInfo',
    name: 'newsInfo',
    component: windowSize.width < 768 ? ()=>import("@/mobile/news/MobileNewsInfo.vue") : ()=>import("@/views/news/NewsInfo.vue")
  },
  {
    path: '/join',
    name: 'join',
    component: windowSize.width < 768 ? ()=>import("@/mobile/MobileJoin.vue") : ()=>import("@/views/Join.vue")
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// 跳转后返回顶部
router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
})

export default router
