export default {
	headMenu: {
		about: "About",
		language: '中文',
		core: "Core",
		industry: "Industry",
		product: "Product",
		news_center: "News Center",
		join_us: "Join Us"
	},
	bottom: {
		tradeWebsite: "Link to the company's foreign trade website",
		aliLink: "Alibaba website link",
		domestic: "Domestic phone number",
		division: "international division",
		comAddress: "Company address",
		address: "No.200,West Deyuan Road, Xinxiang, Henan Province, P.R.China",
		enEmail: "Enterprise email",
		industry: "Application industry",
		classification: "Product classification",
		enterName: "Please enter your name",
		enterMobile: "Please enter your mobile",
		enterMsg: "Please enter a note and leave a message",
		submit: "submit to"
	},
	video: "Watch video",
	detail: "View details",
	learn: "Learn more",
	home: {
		programme: "To provide you with effective filtering solutions",
		icon1: "oil and gas",
		icon2: "Chemical and Polymer",
		icon3: "Equipment Manufacturing",
		icon4: "Mining and Metallurgy",
		icon5: "Power",
		icon6: "Water Treatment",
		icon7: "Energy",
		icon8: "Microelectronics",
		icon9: "Food and beverage",
		icon10: "Air navigation",
		about: "About Shengda",
		clients: "Cooperative clients"
	},
	about: {
		history: "development history",
		qualificationHonors: "Qualifications and honors",
		shengdaQu: "Shengda Qualification",
		shengdaHo: "Received honors",
		fileDownload: "File Download"
	},
	core: {
		researchDevelopment: "R&D Center",
		technology: "Technology",
		service: "sales service",
		clients: "Cooperative clients",
		styb: "Yanbu, Saudi Arabia",
		stdm: "Saudi Dammam",
		db: "Dubai, United Arab Emirates",
		els: "Russia",
		china: "China",
		city: "More than 20 cities including Beijing, Shanghai, Guangzhou, Tianjin, etc"
	},
	productInfo: {
		display: "Product Display",
		introduction: "Equipment Introduction"
	},
	news: {
		corporate: "Corporate News",
		industry: "Industry News",
		tender: "Tender announcement",
		previous: "previous page",
		next: "next page",
		location: "Location",
		home: "Home",
		dynamics: "Enterprise dynamics",
		detail: "News detail",
		center: "News center"
	},
	join: {
		about: "About us",
		home: "Home Page",
		recruitment: "Job Recruitment",
		responsibility: "social responsibility",
		contacts: "Contacts",
		phone: "Phone"
	}
};