<template>
    <div class="top_fixd">
      <div class="left">
        <div class="top_icon" @click="linkToHome">
          <img src="@/assets/index/tab_icon.png">
        </div>
        <div class="tag-list">
          <div class="tag-item" v-for="(item,index) in topLeftList" :key="index" @click="linkToPath(item.path)">
            {{ item.name }}
          </div>
        </div>
      </div>
      <div :class="['right',open?'open':'']">
        <div class="tag-list">
          <div class="tag-item" v-for="(item,index) in topRightList" :key="index" @click="linkToPath(item.path)">
            {{ item.name }}
          </div>
        </div>
        <!--  -->
        <div class="btn_list">
          <div class="search" style="position: relative;">
            <div class="btn_icon search_icon" @click="changeOpen()">
              <img src="@/assets/index/icons/search.png">
            </div>
            <input type="text" class="text" v-model="searchText" @input="searchInput">
            <div class="btn_icon close_icon" @click="changeOpen()">
              <img src="@/assets/index/icons/close.png">
            </div>
            <div class="search_box" v-if="searchList.length!=0">
              <div class="search_txt" v-for="(item,index) in searchList" :key="index" @click="goInfo(item.id)">{{item.name}}</div>
            </div>
          </div>
          <div class="btn_icon">
            <img src="@/assets/index/icons/language.png">
          </div>
        </div>
        <div class="country" @click="changeLanguage()">
          {{ $t('headMenu.language') }}
        </div>
      </div>
    </div>
    <div class="topbox"></div>
    <!-- 切换语言弹窗 -->
    <div class="change_lan" v-if="showChangeLan">
      <div class="mask" @click="changeShow"></div>
      <div class="show_change">
        <div class="lan" @click="changeLanguage('en')">English</div>
        <div class="lan" @click="changeLanguage('cn')">中文</div>
      </div>
    </div>
</template>

<script setup>
import router from '@/router';
import {ref} from 'vue'
import { useI18n } from 'vue-i18n'
import { getCurrentInstance } from "vue";
import { search } from '@/api';
const { $t } = getCurrentInstance().proxy;


let open = ref(false)
function changeOpen(){
  open.value = !open.value
}
// 语言弹窗
let showChangeLan = ref(false)
function changeShow(){
  showChangeLan.value = !showChangeLan.value
}
//切换语言
const { locale } = useI18n()
function changeLanguage(){
  if(locale.value=='en'){
    locale.value = 'zh'
    console.log(locale.value)
    localStorage.setItem('locale','zh')
    window.location.reload()
    return
  }
  if(locale.value=='zh'){
    locale.value = 'en'
    console.log(locale.value)
    localStorage.setItem('locale','en')
    window.location.reload()
    return
  }
}
let topLeftList = ref([
  {
    name:$t('headMenu.about'),
    path:"/about"
  },
  {
    name:$t('headMenu.core'),
    path:"/core"
  },
  {
    name:$t('headMenu.industry'),
    path:"/industry"
  },
  {
    name:$t('headMenu.product'),
    path:"/product"
  }
])
let topRightList = [
  {
    name:$t('headMenu.news_center'),
    path:"/news"
  },
  {
    name:$t('headMenu.join_us'),
    path:"/join"
  }
]
function linkToHome(){
  router.push({path:'/index',query:{refresh:true}})
  
}
function linkToPath(path){
  router.push(path)
}

// 搜索功能
let searchText = ref('')
let searchList = ref([])
function searchInput(){

  let headers = ref('');
  if(locale.value=='zh'){
    headers.value = '0'
  } else {
    headers.value = '1'
  }
  search({kword:searchText.value},{language:headers.value}).then(res=>{
    console.log(res.data);
    searchList.value = res.data.data
  })
}
function goInfo(id){
  router.push({
    path:"/productInfo",
    query:{id}
  })
}
</script>

<style lang="scss" scoped>

  .top_fixd{
    overflow-x: hidden;
    position: fixed;
    top: 0;
    width: 100%;
    box-sizing: border-box;
    height: 3.6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1A59FF;
    padding: 0 5.625rem 0 4.75rem;
    color: #FFFFFF;
    z-index: 99;
    .left{
      display: flex;
      .top_icon{
        height: 3rem;
        width: 9.25rem;
        overflow: hidden;
        cursor: pointer;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .tag-list{
        display: flex;
        align-items: center;
        .tag-item{
          margin-left: 3.375rem;
          cursor: pointer;
          transition: all .5s ease-in-out;
        }
        .tag-item:hover{
          color: #FFB91D;
        }
      }
    }
    .right{
      display: flex;
      align-items: center;
      overflow-x: hidden;
      .tag-list{
        display: flex;
        align-items: center;
        transition: all .5s ease-in-out;
        transform: translateX(0);
        .tag-item{
          margin-right: 3.375rem;
          cursor: pointer;
          transition: all .3s ease-in-out;
        }
        .tag-item:hover{
          color: #FFB91D;
        }
      }
      .btn_list{
        height: 100%;
        align-items: center;
        display: flex;
        .search{
          display: flex;
          align-items: center;
          height: 100%;
          .text{
            transition: all .5s ease-in-out;
            opacity: 1;
            color: #fff;
            outline: none;
            border: none;
            background-color: transparent;
            border-bottom: solid .125rem #fff;
            font-size: .875rem;
            line-height: 1.5rem;
            width: 0;
            opacity: 0;
          }
          .search_box{
            margin-top: .5rem;
            position: fixed;
            top: 3.6rem;
            right: 10.5rem;
            width: 16.5rem;
            background-color: rgba($color: #000, $alpha: .5);
            border-radius: 0 0 .5rem .5rem;
            padding: .75rem .5rem 0;
            box-sizing: border-box;
            max-height: 12.5rem;
            z-index: 99;
            text-align: start;
            overflow-y: auto;
            .search_txt{
              cursor: pointer;
              color: #fff;
              margin-bottom: .75rem;
              transition: all .3s ease-in;
            }
            .search_txt:hover{
              color: #FFB91D;
            }
          }
        }
        .btn_icon{
          width: 1.125rem;
          height: 1.125rem;
          margin-right: .75rem;
          flex-shrink: 0;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .close_icon{
          opacity: 0;
          width: 0;
          margin: 0;
        }
        .search_icon{
          cursor: pointer;
        }
        .btn_icon:first-child{
          margin-right: 0.875rem;
        }
      }
      .country{
        cursor: pointer;
        flex-shrink: 0;
        transition: all .3s ease-in-out;
      }
      .country:hover{
        color: #FFB91D;
      }
    }
    .open{
      .tag-list{
        transform: translateX(-100%);
      }
      .btn_list{
        .search{
          .text{
            width: 12.5rem;
            margin-right: 0.875rem;
            opacity: 1;
          }
        }
        .close_icon{
          width: 1.25rem;
          opacity: 1;
          cursor: pointer;
          margin-right: 1rem;
        }
      }
    }
  }
  .topbox{
    height: 3.6rem;
    width: 100%;
    box-sizing: border-box;
  }
  .change_lan{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba($color: #000000, $alpha: .4);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    .mask{
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba($color: #000000, $alpha: .4);
      z-index: 100;
    }
    .show_change{
      width: 37.5rem;
      height: 25rem;
      border-radius: 2rem;
      background-color: #fff;
      position: relative;
      z-index: 101;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .lan:first-child{
        margin-top: 0;
      }
      .lan{
        font-size: 3rem;
        margin-top: 2.5rem;
        cursor: pointer;
        transition: all .2s ease-in-out;
        color: #222;
        font-weight: bold;
      }
      .lan:hover{
        color: #FFB91D;
      }
    }
  }
</style>