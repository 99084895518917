<template>
  <div class="top_fixd">
    <div class="left" @click="linkToHome()"></div>
    <div class="right">
      <div class="search">
        <img src="@/assets/_mobile/search.png" alt="">
      </div>
      <div class="global">
        <img src="@/assets/_mobile/global.png" alt="">
      </div>
      <div class="country" @click="changeLanguage()">{{ $t('headMenu.language') }}</div>
      <div class="menu" @click="openDraw()">
        <img src="@/assets/_mobile/menu.png" alt="">
      </div>
    </div>
  </div>
  <div class="top_box"></div>
  <el-drawer v-model="headDrawer" class="link_list">
    <div class="select_item" v-for="(item,index) in linkList" :key="index" @click="linkToPath(item.path)">
        {{ item.name }}
      </div>
  </el-drawer>
</template>

<script setup>
import router from '@/router';
import {ref,reactive} from 'vue'
import { useI18n } from 'vue-i18n'
import { getCurrentInstance } from "vue";
const { $t } = getCurrentInstance().proxy;

let headDrawer = ref(false)
let linkList = reactive([
{
    name:$t('headMenu.about'),
    path:"/about"
  },
  {
    name:$t('headMenu.core'),
    path:"/core"
  },
  {
    name:$t('headMenu.industry'),
    path:"/industry"
  },
  {
    name:$t('headMenu.product'),
    path:"/product"
  },
  {
    name:$t('headMenu.news_center'),
    path:"/news"
  },
  {
    name:$t('headMenu.join_us'),
    path:"/join"
  }
])
function openDraw(){
  headDrawer.value = true
}
function linkToHome(){
  router.push({path:'/index',query:{refresh:true}})
}
function linkToPath(path){
  router.push(path)
}
//切换语言
const { locale } = useI18n()
function changeLanguage(){
  if(locale.value=='en'){
    locale.value = 'zh'
    console.log(locale.value)
    localStorage.setItem('locale','zh')
    window.location.reload()
    return
  }
  if(locale.value=='zh'){
    locale.value = 'en'
    console.log(locale.value)
    localStorage.setItem('locale','en')
    window.location.reload()
    return
  }
}
</script>

<style lang='scss' scoped>
.top_fixd{
  z-index: 10;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 98px;
  max-width: 750px;
  overflow-x: hidden;
  box-sizing: border-box;
  padding: 14px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  .left{
    width: 222px;
    height: 70px;
    background: url('@/assets/_mobile/headerIcon.png') no-repeat;
    background-size: cover;
  }
  .right{
    display: flex;
    align-items: center;
    .search,.global,.menu{
      width: 44px;
      height: 44px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .search{
      margin-right: 45px;
    }
    .global{
      margin-right: 14px;
    }
    .country{
      font-size: 28px;
      margin-right: 70px;
    }
  }
}
.top_box{
  height: 98px;
  max-width: 750px;
  overflow-x: hidden;
}
.link_list{
  display: flex;
  flex-direction: column;
  .select_item:first-child{
    margin-top: 0;
  }
  .select_item{
    text-align: start;
    font-size: 24px;
    margin-top: 30px;
  }
}
</style>